import { animate, scroll, inView } from "motion";

document.querySelectorAll("section.gallery").forEach((item) => {
  scroll(animate(item, { opacity: [0.3, 1] }), {
    target: item,
    offset: ["start end", "start .5"]
  });

});

document.querySelectorAll(".keen-slider__slide").forEach((item) => {
  
  inView(
    item,
    ({ target }) => {
 // Start of animation
  (animate(item.querySelectorAll("h4"), 
    { opacity: [0, 1] },
    { delay: 0.5, duration: .7, ease: "ease-in"}
    ))
 // End of animation
},
{ amount: 0.6 }
)
});


